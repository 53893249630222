<template>
  <div
    v-if="getRights($entitiesName.Envelope).read"
    class="is-block is-full-width"
  >
    <div class="columns margin-columns">
      <div class="column menu">
        <div class="envelope-title">
          <h2>{{ $t("common.envelopes") }}</h2>
        </div>
        <div v-if="loading">
          <div class="loader-container">
            <i class="loader"></i>
          </div>
        </div>
        <div v-else-if="envelopes.length > 0">
          <div v-for="envelope in envelopes" :key="'menu_' + envelope.id">
            <div
              class="menu-list"
              @click="onClickMenu(envelope.id)"
              :class="{ selected: envelope.id === envelopeId }"
            >
              <div>{{ envelope.name }}</div>
              <span class="workflow_status">
                {{ envelope.statusWF.status }}
              </span>
            </div>
          </div>
        </div>
        <div v-else class="no-evelope">
          <span> {{ $t("envelopes.none") }}</span>
        </div>
        <div class="is-flex is-justify-content-center mt-5">
          <button
            class="button is-success"
            v-if="getRights($entitiesName.Envelope).read && !loading"
            @click.stop="$refs.addEnvelopModal.open"
          >
            <i class="fa fa-plus"></i>
            {{ $t("envelopes.addEnvelope") }}
          </button>
        </div>
      </div>
      <envelope-details
        ref="envelopeDetails"
        :envelopeId="envelopeId"
        :envelope="envelope"
        :contractId="contractId"
        @delete="deleteEnvelope"
        @refresh="fetchEnvelope"
        :loading="loadingDetails"
      />
    </div>
    <!-- modal -->
    <add-envelope-modal
      ref="addEnvelopModal"
      :contract-id="contractId"
      @created="refreshEnvelopes"
    ></add-envelope-modal>
  </div>
  <article v-else class="message is-danger">
    <div class="message-body">{{ $t("expressions.accessNotGranted") }}</div>
  </article>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "envelopes-manager",
  props: ["contractId"],
  data() {
    return {
      envelopes: [],
      envelopeId: null,
      envelope: {},
      loading: false,
      loadingDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    async initializeData() {
      this.loading = true;
      await this.$api
        .fetchEnvelopes(this.contractId)
        .then((response) => {
          this.envelopes = response;
          if (response.length > 0) {
            this.envelope = response[0];
            this.envelopeId = response[0].id;
          }
        })
        .catch((error) => {
          console.error("Error fetching the envelopes:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async fetchEnvelopes() {
      this.loading = true;
      await this.$api
        .fetchEnvelopes(this.contractId)
        .then((response) => {
          this.envelopes = response;
        })
        .catch((error) => {
          console.error("Error fetching the envelopes:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async fetchEnvelope() {
      this.loadingDetails = true;
      this.$refs.envelopeDetails.updateDocumentsCount(0);
      await this.$api
        .fetchEnvelope(this.envelopeId)
        .then((response) => {
          this.envelope = response;
          this.$router.push({
            path: this.$route.path,
            query: { ...this.$route.query, envelopeId: this.envelopeId },
          });
          const foundEnvelope = this.envelopes.find(
            (item) => item.id === this.envelopeId
          );

          if (foundEnvelope) {
            this.$set(foundEnvelope, "statusWF", response.statusWF);
          }
        })
        .catch((error) => {
          console.error("Error fetching the envelope:", error);
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    },

    onClickMenu(id) {
      this.envelopeId = id;
      this.fetchEnvelope();
      this.setEnvelopeById();
    },

    async refreshEnvelopes(val) {
      this.envelopeId = val;
      this.$refs.envelopeDetails.updateDocumentsCount(0);
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, envelopeId: this.envelopeId },
      });
      await this.fetchEnvelopes();
      this.setEnvelopeById();
    },

    setEnvelopeById() {
      if (this.envelopeId) {
        this.envelope =
          this.envelopes.find((item) => item.id === this.envelopeId) || {};
      }
    },

    async deleteEnvelope() {
      if (this.envelopeId) {
        this.$awn.confirm(this.$t("expressions.deleteEnvelope"), async () => {
          await this.$api
            .deleteEnvelope(this.envelopeId)
            .then(() => {
              this.envelopeId = null;
              this.fetchEnvelopes();
            })
            .catch((error) => {
              console.error("Error fetching the envelopes:", error);
            });
        });
      }
    },
  },
};
</script>

<style scoped>
.envelope-title {
  background-color: #f67a15;
  color: white;
  height: 35px;
  text-align: center;
  align-content: center;
  h2 {
    font-weight: 600;
  }
}
.menu {
  border-right: 1px solid #f6a96f;
  min-width: 300px;
  max-width: 300px;
}

.menu-list {
  padding: 15px 20px;
  font-weight: 700;
  margin-top: 0px;
  cursor: pointer;
  min-height: 51px;
  border-bottom: 1px solid #f6a96f;
  div {
    color: #f67a15;
  }
}

.workflow_status {
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.menu-list:hover {
  background-color: #fdf2ed;
}

.menu div .selected {
  border-left: 3px solid #f67a15;
  background-color: #fdf2ed;
  margin-left: 0px;
  div {
    color: #f67a15;
  }
}

.menu {
  padding: 0px;
}

.loader-container {
  margin-top: 20px;
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  .loader {
    margin: 0 auto;
  }
}

.margin-columns {
  margin: -8px 0px 0px -13px;
}

button > i {
  margin-right: 7px;
}

.no-evelope {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
</style>
