var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('paginate',{attrs:{"paginate":_vm.pagination,"showOptions":true,"options":_vm.paginateOptions},on:{"change":_vm.paginate}}),_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("common.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.averageRating")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.evaluation")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.safety")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.procurement")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.project")))])])]),_c('tbody',[(_vm.companies.length === 0 && _vm.loading === true)?_c('tr',[_vm._m(0)]):_vm._e(),(_vm.companies.length === 0 && _vm.loading === false)?_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v(_vm._s(_vm.$t("expressions.noData")))])]):_vm._e(),_vm._l((_vm.companies),function(company,companyIndex){return [_c('tr',{key:company.id,staticClass:"selectable",class:{ selected: _vm.expandedAgencyIndex === companyIndex },on:{"click":function($event){return _vm.toggleAgencies(companyIndex)}}},[_c('td',[_vm._v(_vm._s(company.name))]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                  red: company.average.score < 3.33,
                  yellow:
                    company.average.score >= 3.33 &&
                    company.average.score < 6.66,
                  green: company.average.score >= 6.66,
                }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(_vm._s(company.average.score.toFixed(1)))])])]),_c('td',[_vm._v(_vm._s(company.average.count))]),_c('td',[_vm._v(_vm._s(company.details.safety.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(company.details.procurement.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(company.details.projects.score.toFixed(1)))])]),(_vm.expandedAgencyIndex === companyIndex)?[(_vm.loading)?[_c('tr',{staticClass:"agencies last-agency"},[_vm._m(1,true)])]:_vm._e(),_vm._l((_vm.agencies),function(agency,agencyIndex){return [_c('tr',{key:company.id + '-' + agency.id,staticClass:"agencies selectable",class:{
                'last-agency': agencyIndex === _vm.agencies.length - 1,
                'agency-selected': _vm.expandedProjectIndex === agencyIndex,
              },on:{"click":function($event){return _vm.toggleProjects(agencyIndex)}}},[_c('td',{staticStyle:{"padding-left":"30px"}},[_vm._v(_vm._s(agency.name))]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                      red: agency.average.score < 3.33,
                      yellow:
                        agency.average.score >= 3.33 &&
                        agency.average.score < 6.66,
                      green: agency.average.score >= 6.66,
                    }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(" "+_vm._s(agency.average.score.toFixed(1))+" ")])])]),_c('td',[_vm._v(_vm._s(agency.average.count))]),_c('td',[_vm._v(_vm._s(agency.details.safety.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(agency.details.procurement.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(agency.details.projects.score.toFixed(1)))])]),(_vm.expandedProjectIndex === agencyIndex)?[(_vm.loadingProject)?[_c('tr',{staticClass:"project last-project"},[_vm._m(2,true)])]:_vm._l((_vm.projects),function(project,projectIndex){return _c('tr',{key:agency.id + '-' + project.id,staticClass:"projects",class:{
                    'last-project': projectIndex === _vm.projects.length - 1,
                  }},[_c('td',{staticStyle:{"padding-left":"60px"}},[_vm._v(_vm._s(project.name))]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                          red: project.average.score < 3.33,
                          yellow:
                            project.average.score >= 3.33 &&
                            project.average.score < 6.66,
                          green: project.average.score >= 6.66,
                        }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(" "+_vm._s(project.average.score.toFixed(1))+" ")])])]),_c('td',[_vm._v(_vm._s(project.average.count))]),_c('td',[_vm._v(_vm._s(project.details.safety.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(project.details.procurement.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(project.details.projects.score.toFixed(1)))])])})]:_vm._e()]})]:_vm._e()]})],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"loader-container",attrs:{"colspan":"6"}},[_c('i',{staticClass:"loader"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"loader-container",attrs:{"colspan":"6"}},[_c('i',{staticClass:"loader"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"loader-container",attrs:{"colspan":"6"}},[_c('i',{staticClass:"loader"})])}]

export { render, staticRenderFns }