<template>
  <table class="table is-fullwidth" ref="documentsTable">
    <thead>
      <tr class="has-background-light">
        <th>#</th>
        <th style="width: 100%">{{ $t("expressions.fileName") }}</th>
        <th>{{ $t("common.type") }}</th>
        <th>{{ $t("common.date") }}</th>
        <th>{{ $t("common.action") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!documents.length">
        <td :colspan="5" class="has-text-centered">
          {{ $t("common.noRecords") }}
        </td>
      </tr>
      <tr
        v-for="(document, idx) in documents"
        :key="'document_' + document.id + '_' + idx"
      >
        <td>{{ idx + 1 }}</td>
        <td style="width: 100%">{{ document.filename }}</td>
        <td style="text-wrap: nowrap">
          {{ document.type && document.type.name }}
        </td>
        <td>{{ document.createdAt | reverseDate }}</td>
        <td>
          <div class="document-actions">
            <form
              class="inline-form"
              method="POST"
              target="_blank"
              :action="`${API}/document/${document.id}`"
            >
              <button class="button is-primary is-small" type="submit">
                <i class="fa fa-download"></i>
                {{ $t("common.download") }}
              </button>
              <input type="hidden" name="token" :value="authToken" />
            </form>
            <button
              v-if="deletable"
              class="button is-danger is-small"
              @click="deleteDocument(document.id)"
            >
              <i class="fa fa-trash"></i>
              {{ $t("common.delete") }}
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<style scoped>
.document-actions {
  display: flex;
  button:last-child {
    margin-left: 4px;
  }
}
button > i {
  margin-right: 7px;
}
</style>
<script>
import axios from "axios";
export default {
  name: "documents-table",
  props: {
    objectId: [String, Number],
    objectName: String,
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: [],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
    };
  },
  computed: {},
  mounted() {
    this.fetchDocuments();
  },
  methods: {
    fetchDocuments() {
      axios
        .get(`/documents?entity=${this.objectName}&id=${this.objectId}`)
        .then((res) => {
          this.documents = res.data;
        });
    },
    deleteDocument(documentId) {
      return this.$awn.confirm(this.$t("expressions.deleteDocument"), () =>
        axios.delete(`/document/${documentId}`).then((response) => {
          if (response.data.success) {
            this.fetchDocuments();
          }
        })
      );
    },
  },
  watch: {
    "documents.length": function (v) {
      this.$emit("updated", v);
    },
  },
};
</script>
